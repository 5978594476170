var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "extra-component-form-validation-demo" } },
    [
      _vm._m(0),
      _c("form-validation-simple"),
      _c("form-validation-multiple-rules"),
      _c("div", { staticClass: "vx-row match-height" }, [
        _c(
          "div",
          { staticClass: "vx-col sm:w-1/2 w-full" },
          [
            _c("form-validation-custom-error-messages", {
              staticClass: "mb-base",
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col sm:w-1/2 w-full" },
          [_c("form-validation-events", { staticClass: "mb-base" })],
          1
        ),
      ]),
      _c("form-validation-types"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-4" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://logaretm.github.io/vee-validate/guide/",
            target: "_blank",
            rel: "nofollow",
          },
        },
        [_vm._v("vee-validate")]
      ),
      _vm._v(
        " is a Template Based Validation Framework for Vue.js. Read full documentation "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://baianat.github.io/vee-validate/guide/",
            target: "_blank",
            rel: "nofollow",
          },
        },
        [_vm._v("here")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }