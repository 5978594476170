var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Simple form validation", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Simple form validation before submitting form. Add "),
        _c("code", [_vm._v("v-validate")]),
        _vm._v(" directive to input."),
      ]),
      _c("div", { staticClass: "mt-5" }, [
        _c(
          "form",
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "mt-5",
              attrs: { placeholder: "Input 1", name: "input1" },
              model: {
                value: _vm.value1,
                callback: function ($$v) {
                  _vm.value1 = $$v
                },
                expression: "value1",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("input1"),
                    expression: "errors.has('input1')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("input1")))]
            ),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
              ],
              staticClass: "mt-5",
              attrs: { placeholder: "Input 2", name: "input2" },
              model: {
                value: _vm.value2,
                callback: function ($$v) {
                  _vm.value2 = $$v
                },
                expression: "value2",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("input2"),
                    expression: "errors.has('input2')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("input2")))]
            ),
            _c(
              "vs-button",
              {
                staticClass: "mt-5 block",
                attrs: { type: "filled" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submitForm.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Submit")]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <form>\n    <vs-input size="large" v-validate="\'required\'" placeholder="Input 1" name="input1" v-model="value1" class="mt-5" />\n    <span class="text-danger text-sm" v-show="errors.has(\'input1\')">' +
            _vm._s("{{ errors.first('input1') }}") +
            '</span>\n\n    <vs-input size="large" v-validate="\'required\'" placeholder="Input 2" name="input2" v-model="value2" class="mt-5" />\n    <span class="text-danger text-sm" v-show="errors.has(\'input2\')">' +
            _vm._s("{{ errors.first('input2') }}") +
            '</span>\n\n    <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Submit</vs-button>\n  </form>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      value1: "",\n      value2: "",\n    }\n  },\n  methods: {\n    submitForm() {\n      this.$validator.validateAll().then(result => {\n        if(result) {\n          // if form have no errors\n          alert("form submitted!");\n        }else{\n          // form have errors\n        }\n      })\n    }\n  },\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }