var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Validation types", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "VeeValidate comes with a bunch of validation rules out of the box and cover most validation needs"
        ),
      ]),
      _c("div", { staticClass: "mt-5" }, [
        _c(
          "form",
          [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label: "This field is required",
                      name: "required",
                    },
                    model: {
                      value: _vm.required,
                      callback: function ($$v) {
                        _vm.required = $$v
                      },
                      expression: "required",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("required"),
                          expression: "errors.has('required')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("required")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "alpha",
                        expression: "'alpha'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label: "Only alphabetic characters",
                      name: "alpha",
                    },
                    model: {
                      value: _vm.alpha,
                      callback: function ($$v) {
                        _vm.alpha = $$v
                      },
                      expression: "alpha",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("alpha"),
                          expression: "errors.has('alpha')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("alpha")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "alpha_dash",
                        expression: "'alpha_dash'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label:
                        "Only alphabetic characters, numbers, dashes or underscores",
                      name: "alpha_dash",
                    },
                    model: {
                      value: _vm.alpha_dash,
                      callback: function ($$v) {
                        _vm.alpha_dash = $$v
                      },
                      expression: "alpha_dash",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("alpha_dash"),
                          expression: "errors.has('alpha_dash')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("alpha_dash")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "alpha_num",
                        expression: "'alpha_num'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label: "May contain alphabetic characters or numbers",
                      name: "alpha_num",
                    },
                    model: {
                      value: _vm.alpha_num,
                      callback: function ($$v) {
                        _vm.alpha_num = $$v
                      },
                      expression: "alpha_num",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("alpha_num"),
                          expression: "errors.has('alpha_num')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("alpha_num")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "numeric",
                        expression: "'numeric'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label: "Must only consist of numbers",
                      name: "numeric",
                    },
                    model: {
                      value: _vm.numeric,
                      callback: function ($$v) {
                        _vm.numeric = $$v
                      },
                      expression: "numeric",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("numeric"),
                          expression: "errors.has('numeric')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("numeric")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "alpha_spaces",
                        expression: "'alpha_spaces'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label: "Only alphabetic characters or spaces",
                      name: "alpha_spaces",
                    },
                    model: {
                      value: _vm.alpha_spaces,
                      callback: function ($$v) {
                        _vm.alpha_spaces = $$v
                      },
                      expression: "alpha_spaces",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("alpha_spaces"),
                          expression: "errors.has('alpha_spaces')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("alpha_spaces")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "email",
                        expression: "'email'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: { label: "Must be a valid email", name: "email" },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("email"),
                          expression: "errors.has('email')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("email")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "url:require_protocol",
                        expression: "'url:require_protocol'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      "data-vv-as": "field",
                      label: "Must be a valid url",
                      name: "url",
                    },
                    model: {
                      value: _vm.url,
                      callback: function ($$v) {
                        _vm.url = $$v
                      },
                      expression: "url",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("url"),
                          expression: "errors.has('url')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("url")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "min:3",
                        expression: "'min:3'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label:
                        "Length should not be less than the specified length : 3",
                      name: "min",
                    },
                    model: {
                      value: _vm.min,
                      callback: function ($$v) {
                        _vm.min = $$v
                      },
                      expression: "min",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("min"),
                          expression: "errors.has('min')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("min")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:6",
                        expression: "'max:6'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label: "Length may not exceed the specified length : 6",
                      name: "max",
                    },
                    model: {
                      value: _vm.max,
                      callback: function ($$v) {
                        _vm.max = $$v
                      },
                      expression: "max",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("max"),
                          expression: "errors.has('max')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("max")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "min:6|max:10",
                        expression: "'min:6|max:10'",
                      },
                    ],
                    ref: "password",
                    staticClass: "mt-5 w-full",
                    attrs: {
                      type: "password",
                      label: "Password 1",
                      name: "password",
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("password"),
                          expression: "errors.has('password')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("password")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "min:6|max:10|confirmed:password",
                        expression: "'min:6|max:10|confirmed:password'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      type: "password",
                      label: "Confirm Password",
                      name: "confirm_password",
                      "data-vv-as": "password",
                    },
                    model: {
                      value: _vm.confirm_password,
                      callback: function ($$v) {
                        _vm.confirm_password = $$v
                      },
                      expression: "confirm_password",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("confirm_password"),
                          expression: "errors.has('confirm_password')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("confirm_password")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "date_format:dd/MM/yyyy",
                        expression: "'date_format:dd/MM/yyyy'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label: "Date Format: dd/MM/yyyy",
                      name: "date_format",
                    },
                    model: {
                      value: _vm.date_format,
                      callback: function ($$v) {
                        _vm.date_format = $$v
                      },
                      expression: "date_format",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("date_format"),
                          expression: "errors.has('date_format')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("date_format")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "between:1,11",
                        expression: "'between:1,11'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label:
                        "Numeric value between minimum value and a maximum value : 1 and 11",
                      name: "between",
                    },
                    model: {
                      value: _vm.between,
                      callback: function ($$v) {
                        _vm.between = $$v
                      },
                      expression: "between",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("between"),
                          expression: "errors.has('between')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("between")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "credit_card",
                        expression: "'credit_card'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: { label: "Valid credit card", name: "credit_card" },
                    model: {
                      value: _vm.credit_card,
                      callback: function ($$v) {
                        _vm.credit_card = $$v
                      },
                      expression: "credit_card",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("credit_card"),
                          expression: "errors.has('credit_card')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("credit_card")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value:
                          "date_format:dd/MM/yyyy|date_between:10/09/2016,20/09/2016",
                        expression:
                          "'date_format:dd/MM/yyyy|date_between:10/09/2016,20/09/2016'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label:
                        "Must be a valid date between the two dates specified : 10/09/2016 - 20/09/2016",
                      name: "date_between",
                    },
                    model: {
                      value: _vm.date_between,
                      callback: function ($$v) {
                        _vm.date_between = $$v
                      },
                      expression: "date_between",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("date_between"),
                          expression: "errors.has('date_between')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("date_between")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "digits:3",
                        expression: "'digits:3'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label:
                        "Must be numeric and have the specified number of digits : 3",
                      name: "digits",
                    },
                    model: {
                      value: _vm.digits,
                      callback: function ($$v) {
                        _vm.digits = $$v
                      },
                      expression: "digits",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("digits"),
                          expression: "errors.has('digits')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("digits")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c(
                    "vs-select",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "included:1,3",
                          expression: "'included:1,3'",
                        },
                      ],
                      staticClass: "select-large mt-5 w-full",
                      attrs: {
                        name: "included",
                        label:
                          "Must have a value that is in the specified list : 1 or 3",
                      },
                      model: {
                        value: _vm.select1,
                        callback: function ($$v) {
                          _vm.select1 = $$v
                        },
                        expression: "select1",
                      },
                    },
                    _vm._l(_vm.options1, function (item, index) {
                      return _c("vs-select-item", {
                        key: index,
                        attrs: { value: item.value, text: item.text },
                      })
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("included"),
                          expression: "errors.has('included')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("included")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max_value:100",
                        expression: "'max_value:100'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label:
                        "Numeric value and must not be greater than the specified value : 100",
                      name: "max_value",
                    },
                    model: {
                      value: _vm.max_value,
                      callback: function ($$v) {
                        _vm.max_value = $$v
                      },
                      expression: "max_value",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("max_value"),
                          expression: "errors.has('max_value')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("max_value")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "min_value:10",
                        expression: "'min_value:10'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label:
                        "Numeric value and must not be less than the specified value : 10",
                      name: "min_value",
                    },
                    model: {
                      value: _vm.min_value,
                      callback: function ($$v) {
                        _vm.min_value = $$v
                      },
                      expression: "min_value",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("min_value"),
                          expression: "errors.has('min_value')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("min_value")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { regex: "^([0-9]+)$" },
                        expression: "{regex: '^([0-9]+)$' }",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      label:
                        "Must match the specified regular expression : ^([0-9]+)$ - numbers only",
                      name: "regex",
                    },
                    model: {
                      value: _vm.regex,
                      callback: function ($$v) {
                        _vm.regex = $$v
                      },
                      expression: "regex",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("regex"),
                          expression: "errors.has('regex')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("regex")))]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "vs-button",
              {
                staticClass: "mt-5 block",
                attrs: { type: "filled" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submitForm.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Submit")]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <form>\n    <div class="vx-row">\n\n      <!-- required -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'required\'" label="This field is required" name="required" v-model="required" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'required\')">' +
            _vm._s("{{ errors.first('required') }}") +
            '</span>\n      </div>\n\n      <!-- Only alphabetic characters -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'alpha\'" label="Only alphabetic characters" name="alpha" v-model="alpha" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'alpha\')">' +
            _vm._s("{{ errors.first('alpha') }}") +
            '</span>\n      </div>\n\n      <!-- Only alphabetic characters, numbers, dashes or underscores -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'alpha_dash\'" label="Only alphabetic characters, numbers, dashes or underscores" name="alpha_dash" v-model="alpha_dash" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'alpha_dash\')">' +
            _vm._s("{{ errors.first('alpha_dash') }}") +
            '</span>\n      </div>\n\n      <!-- May contain alphabetic characters or numbers -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'alpha_num\'" label="May contain alphabetic characters or numbers" name="alpha_num" v-model="alpha_num" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'alpha_num\')">' +
            _vm._s("{{ errors.first('alpha_num') }}") +
            '</span>\n      </div>\n\n      <!-- Must only consist of numbers -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'numeric\'" label="Must only consist of numbers" name="numeric" v-model="numeric" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'numeric\')">' +
            _vm._s("{{ errors.first('numeric') }}") +
            '</span>\n      </div>\n\n      <!-- Only alphabetic characters or spaces -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'alpha_spaces\'" label="Only alphabetic characters or spaces" name="alpha_spaces" v-model="alpha_spaces" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'alpha_spaces\')">' +
            _vm._s("{{ errors.first('alpha_spaces') }}") +
            '</span>\n      </div>\n\n      <!-- Must be a valid email -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'email\'" label="Must be a valid email" name="email" v-model="email" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'email\')">' +
            _vm._s("{{ errors.first('email') }}") +
            '</span>\n      </div>\n\n      <!-- Must be a valid url -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'url:require_protocol\'" data-vv-as="field" label="Must be a valid url" name="url" v-model="url" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'url\')">' +
            _vm._s("{{ errors.first('url') }}") +
            '</span>\n      </div>\n\n      <!-- Length should not be less than the specified length : 3 -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'min:3\'" label="Length should not be less than the specified length : 3" name="min" v-model="min" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'min\')">' +
            _vm._s("{{ errors.first('min') }}") +
            '</span>\n      </div>\n\n      <!-- Length may not exceed the specified length : 6 -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'max:6\'" label="Length may not exceed the specified length : 6" name="max" v-model="max" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'max\')">' +
            _vm._s("{{ errors.first('max') }}") +
            '</span>\n      </div>\n\n      <!-- Password 1 -->\n      <div class="vx-col w-1/2">\n        <vs-input type="password" size="large" v-validate="\'min:6|max:10\'" label="Password 1" name="password" v-model="password" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'password\')">' +
            _vm._s("{{ errors.first('password') }}") +
            '</span>\n      </div>\n\n      <!-- Confirm Password -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'min:6|max:10|confirmed:password\'" label="Confirm Password" name="confirm_password" v-model="confirm_password" class="mt-5 w-full" data-vv-as="password" />\n        <span class="text-danger text-sm" v-show="errors.has(\'confirm_password\')">' +
            _vm._s("{{ errors.first('confirm_password') }}") +
            '</span>\n      </div>\n\n      <!-- Date Format: dd/MM/yyyy -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'date_format:dd/MM/yyyy\'" label="Date Format: dd/MM/yyyy" name="date_format" v-model="date_format" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'date_format\')">' +
            _vm._s("{{ errors.first('date_format') }}") +
            '</span>\n      </div>\n\n      <!-- Numeric value between minimum value and a maximum value : 1 and 11 -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'between:1,11\'" label="Numeric value between minimum value and a maximum value : 1 and 11" name="between" v-model="between" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'between\')">' +
            _vm._s("{{ errors.first('between') }}") +
            '</span>\n      </div>\n\n      <!-- Valid credit card -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'credit_card\'" label="Valid credit card" name="credit_card" v-model="credit_card" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'credit_card\')">' +
            _vm._s("{{ errors.first('credit_card') }}") +
            '</span>\n      </div>\n\n      <!-- Must be a valid date between the two dates specified : 10/09/2016 - 20/09/2016 -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'date_format:dd/MM/yyyy|date_between:10/09/2016,20/09/2016\'" label="Must be a valid date between the two dates specified : 10/09/2016 - 20/09/2016" name="date_between" v-model="date_between" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'date_between\')">' +
            _vm._s("{{ errors.first('date_between') }}") +
            '</span>\n      </div>\n\n      <!-- Must be numeric and have the specified number of digits : 3 -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'digits:3\'" label="Must be numeric and have the specified number of digits : 3" name="digits" v-model="digits" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'digits\')">' +
            _vm._s("{{ errors.first('digits') }}") +
            '</span>\n      </div>\n\n      <!-- Must have a value that is in the specified list : 1 or 3 -->\n      <div class="vx-col w-1/2">\n        <vs-select v-validate="\'included:1,3\'" v-model="select1" name="included" label="Must have a value that is in the specified list : 1 or 3" class="select-large mt-5 w-full">\n          <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in options1" />\n        </vs-select>\n        <span class="text-danger text-sm" v-show="errors.has(\'included\')">' +
            _vm._s("{{ errors.first('included') }}") +
            '</span>\n      </div>\n\n      <!-- Numeric value and must not be greater than the specified value : 100 -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'max_value:100\'" label="Numeric value and must not be greater than the specified value : 100" name="max_value" v-model="max_value" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'max_value\')">' +
            _vm._s("{{ errors.first('max_value') }}") +
            '</span>\n      </div>\n\n      <!-- Numeric value and must not be less than the specified value : 10 -->\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'min_value:10\'" label="Numeric value and must not be less than the specified value : 10" name="min_value" v-model="min_value" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'min_value\')">' +
            _vm._s("{{ errors.first('min_value') }}") +
            '</span>\n      </div>\n\n      <!-- Must match the specified regular expression : ^([0-9]+)$ - numbers only -->\n      <div class="vx-col w-full">\n        <vs-input size="large" v-validate="{regex: \'^([0-9]+)$\' }" label="Must match the specified regular expression : ^([0-9]+)$ - numbers only" name="regex" v-model="regex" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'regex\')">' +
            _vm._s("{{ errors.first('regex') }}") +
            '</span>\n      </div>\n    </div>\n    <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Submit</vs-button>\n  </form>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      required: "",\n      alpha: "",\n      alpha_dash: "",\n      alpha_num: "",\n      numeric: "",\n      alpha_spaces: "",\n      email: "",\n      url: "",\n      min: "",\n      max: "",\n      password: "",\n      confirm_password: "",\n      date_format: "",\n      between: "",\n      credit_card: "",\n      date_between: "",\n      digits: "",\n      select1: "",\n      options1: [\n        { text: "One", value: 1 },\n        { text: "Two", value: 2 },\n        { text: "Three", value: 3 },\n        { text: "Four", value: 4 },\n        { text: "Five", value: 5 },\n      ],\n      max_value: "",\n      min_value: "",\n      regex: "",\n    }\n  },\n  methods: {\n    submitForm() {\n      this.$validator.validateAll().then(result => {\n        if (result) {\n          // if form have no errors\n          alert("form submitted!");\n        } else {\n          // form have errors\n        }\n      })\n    }\n  },\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }