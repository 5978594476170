var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Custom error messages", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "With vee-validate you can display your own custom error messages"
        ),
      ]),
      _c("div", { staticClass: "mt-5" }, [
        _c(
          "form",
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|alpha",
                  expression: "'required|alpha'",
                },
              ],
              staticClass: "mt-5",
              attrs: { placeholder: "First Name", name: "first_name" },
              model: {
                value: _vm.first_name,
                callback: function ($$v) {
                  _vm.first_name = $$v
                },
                expression: "first_name",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("first_name"),
                    expression: "errors.has('first_name')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("first_name")))]
            ),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|alpha",
                  expression: "'required|alpha'",
                },
              ],
              staticClass: "mt-5",
              attrs: { placeholder: "Last Name", name: "last_name" },
              model: {
                value: _vm.last_name,
                callback: function ($$v) {
                  _vm.last_name = $$v
                },
                expression: "last_name",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("last_name"),
                    expression: "errors.has('last_name')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("last_name")))]
            ),
            _c(
              "vs-button",
              {
                staticClass: "mt-5 block",
                attrs: { type: "filled" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submitForm.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Submit")]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <form>\n    <vs-input size="large" v-validate="\'required|alpha\'" placeholder="First Name" name="first_name" v-model="first_name" class="mt-5" />\n    <span class="text-danger text-sm" v-show="errors.has(\'first_name\')">' +
            _vm._s("{{ errors.first('first_name') }}") +
            '</span>\n\n    <vs-input size="large" v-validate="\'required|alpha\'" placeholder="Last Name" name="last_name" v-model="last_name" class="mt-5" />\n    <span class="text-danger text-sm" v-show="errors.has(\'last_name\')">' +
            _vm._s("{{ errors.first('last_name') }}") +
            '</span>\n    <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Submit</vs-button>\n  </form>\n</template>\n\n<script>\n// For custom error message\nimport { Validator } from \'vee-validate\';\nconst dict = {\n  custom: {\n    first_name: {\n      required: \'Please enter your first name\',\n      alpha: "Your first name may only contain alphabetic characters"\n    },\n    last_name: {\n      required: \'Please enter your last name\',\n      alpha: "Your last name may only contain alphabetic characters"\n    },\n  }\n};\n\n// register custom messages\nValidator.localize(\'en\', dict);\n\nexport default {\n  data() {\n    return {\n      first_name: "",\n      last_name: "",\n    }\n  },\n  methods: {\n    submitForm() {\n      this.$validator.validateAll().then(result => {\n        if (result) {\n          // if form have no errors\n          alert("form submitted!");\n        } else {\n          // form have errors\n        }\n      })\n    }\n  },\n}\n</script>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }