var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Validating multiple rules", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can validate multiple rules using "),
        _c("code", [_vm._v("|")]),
        _vm._v(". You can find more validation rules "),
        _c(
          "a",
          {
            attrs: {
              href: "https://baianat.github.io/vee-validate/guide/rules.html",
              target: "_blank",
              rel: "nofollow",
            },
          },
          [_vm._v("here")]
        ),
      ]),
      _c("div", { staticClass: "mt-5" }, [
        _c(
          "form",
          [
            _c("div", { staticClass: "vx-row lg:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|alpha",
                        expression: "'required|alpha'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: { placeholder: "Your Name", name: "name" },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("name"),
                          expression: "errors.has('name')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("name")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col lg:w-1/2 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|alpha_dash",
                        expression: "'required|alpha_dash'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: { placeholder: "Your Username", name: "username" },
                    model: {
                      value: _vm.username,
                      callback: function ($$v) {
                        _vm.username = $$v
                      },
                      expression: "username",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("username"),
                          expression: "errors.has('username')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("username")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col lg:w-1/2 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|email",
                        expression: "'required|email'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: { placeholder: "Your Email", name: "email" },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("email"),
                          expression: "errors.has('email')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("email")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col lg:w-1/2 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:6|max:10",
                        expression: "'required|min:6|max:10'",
                      },
                    ],
                    ref: "password",
                    staticClass: "mt-5 w-full",
                    attrs: {
                      type: "password",
                      placeholder: "Your Password",
                      name: "password",
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("password"),
                          expression: "errors.has('password')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("password")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col lg:w-1/2 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:6|max:10|confirmed:password",
                        expression:
                          "'required|min:6|max:10|confirmed:password'",
                      },
                    ],
                    staticClass: "mt-5 w-full",
                    attrs: {
                      type: "password",
                      "data-vv-as": "password",
                      placeholder: "Confirm Password",
                      name: "confirm_password",
                    },
                    model: {
                      value: _vm.confirm_password,
                      callback: function ($$v) {
                        _vm.confirm_password = $$v
                      },
                      expression: "confirm_password",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("confirm_password"),
                          expression: "errors.has('confirm_password')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("confirm_password")))]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "vs-button",
              {
                staticClass: "mt-5 block",
                attrs: { type: "filled" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submitForm.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Submit")]
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <form>\n    <div class="vx-row w-1/2">\n\n      <div class="vx-col w-full">\n        <vs-input size="large" v-validate="\'required|alpha\'" placeholder="Your Name" name="name" v-model="name" class="mt-5 w-full" />\n        <span class="text-danger text-sm"  v-show="errors.has(\'name\')">' +
            _vm._s("{{ errors.first('name') }}") +
            '</span>\n      </div>\n\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'required|alpha_dash\'" placeholder="Your Username" name="username" v-model="username" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'username\')">' +
            _vm._s("{{ errors.first('username') }}") +
            '</span>\n      </div>\n\n      <div class="vx-col w-1/2">\n        <vs-input size="large" v-validate="\'required|email\'" placeholder="Your Email" name="email" v-model="email" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'email\')">' +
            _vm._s("{{ errors.first('email') }}") +
            '</span>\n      </div>\n\n      <div class="vx-col w-1/2">\n        <vs-input type="password" size="large" v-validate="\'required|min:6|max:10\'" ref="password" placeholder="Your Password" name="password" v-model="password" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'password\')">' +
            _vm._s("{{ errors.first('password') }}") +
            '</span>\n      </div>\n\n      <div class="vx-col w-1/2">\n        <vs-input type="password" size="large" v-validate="\'required|min:6|max:10|confirmed:password\'" data-vv-as="password" placeholder="Confirm Password" name="confirm_password" v-model="confirm_password" class="mt-5 w-full" />\n        <span class="text-danger text-sm" v-show="errors.has(\'confirm_password\')">' +
            _vm._s("{{ errors.first('confirm_password') }}") +
            '</span>\n      </div>\n    </div>\n\n    <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Submit</vs-button>\n  </form>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      name: "",\n      username: "",\n      email: "",\n      password: "",\n      confirm_password: "",\n    }\n  },\n  methods: {\n    submitForm() {\n      this.$validator.validateAll().then(result => {\n        if (result) {\n          // if form have no errors\n          alert("form submitted!");\n        } else {\n          // form have errors\n        }\n      })\n    }\n  },\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }